import styled from 'styled-components';
const RegularFont = 'Rubik-Regular';
const MediumFont = 'Rubik-Medium';
// const ExtraBold = 'Rubik-Bold';

const FilterButton = styled.div`
button{
    border-radius: 3px;
background: ${({ active }) => active ? "#005C87" : "#F7F5EB"};
color:${({ active }) => active ? "white" : "rgba(0, 92, 135, 0.60)"};
padding:10px 15px;
border:1px solid rgba(0, 92, 135, 0.60);
margin-right:15px;
font-family:${({ active }) => active ? MediumFont : RegularFont};
:hover{
    color:white;
    background:#005C87;
}

}
`;

const SurveyPreviewStyle = styled.div`
background:#005C87;
${'' /* background-size: 169px auto; */}
width:100%;
height:169px;
z-index:10;
position:absolute;
`;

const SurveyPreviewHeader = styled.div`
z-index:100
border-radius: 6px;
background: #FFF;
box-shadow: 0px 0px 15px 0px rgba(0, 92, 135, 0.25);
width: 920px;
margin-top:64px;
padding:25px;

.title-row{
    display:flex;
justify-content:space-between;

.category{
    color: #005C87;
font-family: Rubik-Medium;
font-size: 24px;
font-style: normal;
font-weight: 500;
line-height: 24px; /* 100% */
}

.company{
    color: rgba(0, 92, 135, 0.60);
text-align: justify;
font-family: Rubik;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 142.857% */
}

.right-side{
    display:flex;
    align-items:center;
}
}

.survey-title{
    color: #005C87;
text-align: justify;
font-family: Rubik;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 20px; 
}

.rect{
    width: 16px;
height: 16px;
background: var(--Background, #F7F5EB);
}

.questions{
    color: var(--navy_blues_60, rgba(0, 92, 135, 0.60));
text-align: justify;
font-family: Rubik;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px; 
margin-left:5px;
}
`;

const QuestionCard = styled.div`
border-radius: 6px;
background: #FFF;
box-shadow: 0px 0px 15px 0px rgba(0, 92, 135, 0.25);
width: 920px;
margin-top:25px;
${'' /* padding:25px; */}
.question-row{
    display:flex;
    justify-content:space-between;
    padding:15px 15px 0px 15px;

    .title{
        color: var(--Navy_blue, #005C87);
font-family: Rubik-Medium;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: 24px; /* 133.333% */
word-break:${({ isBreak }) => isBreak ? "break-all" : ""};
    }

    .type{
        color: var(--navy_blues_60, rgba(0, 92, 135, 0.60));
text-align: right;
font-family: Rubik;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 24px;
text-transform:capitalize;
    }
}

.ans{
    display:flex;
    align-items:center;
    justify-content:center;
    margin:25px;
    border-radius: 6px;
border: 1px solid var(--navy-blue-10, rgba(0, 92, 135, 0.10));
background: var(--Background, #F7F5EB);
width: 870px;
min-height: 60px;
flex-shrink: 0;

.likert-title{
    color: var(--navy_blues_60, rgba(0, 92, 135, 0.60));
text-align: center;
font-family: Rubik;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 24px;
text-transform:capitalize;

}

}

.multiple-choice{
    display:flex;
    text-transform:capitalize;
    align-items:center;
    flex-wrap:wrap;
    ${'' /* margin:20px 25px; */}
    margin:20px 25px;

    .flex-item:not(:nth-child(2n)) {
  margin-right: 0;
}
  

  ${'' /* .recommended-survey{
    .flex-item:not(:nth-child(3n)) {
  margin-right: 0;
} */}
  }

    .option{
        display:flex;
        align-items:center;
        justify-content:center;
        width: 395px;
min-height: 40px;
flex-shrink: 0;
border-radius: 5px;
background: var(--White, #FFF);
color: var(--Navy_blue, #005C87);
text-align: center;
font-family: Rubik;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 24px;
margin:5px;
padding:5px;

    }

}

`;

export {FilterButton, SurveyPreviewStyle, SurveyPreviewHeader, QuestionCard}